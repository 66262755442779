<template>
  <v-main>
    <v-dialog persistent no-click-animation width="660" v-model="open">
      <v-card>
        <v-app-bar flat color="primary">
          <div class="white--text">
            Система для проведения экспертизы цифровизации КР
          </div>
        </v-app-bar>
        <slot></slot>
      </v-card>
    </v-dialog>
  </v-main>
</template>

<script>
export default {
  data() {
    return {
      open: true,
    }
  },
}
</script>
