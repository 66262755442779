<template>
  <LoginDialogCard>
    <v-card-text>
      <v-text-field
        v-model="email"
        label="Логин (email)"
        @input="onInput"
      ></v-text-field>
      <v-text-field
        v-model="password"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        :type="showPassword ? 'text' : 'password'"
        @click:append="showPassword = !showPassword"
        label="Пароль"
        @input="onInput"
      ></v-text-field>
      <div class="d-flex align-center" style="min-height: 48px">
        <v-btn @click="onLogin" color="primary" class="mr-4">Войти</v-btn>
        <v-alert v-if="showAlert" dense outlined type="error" class="my-0">
          {{ alertMessage }}
        </v-alert>
      </div>
    </v-card-text>
  </LoginDialogCard>
</template>

<script>
import axios from 'axios'
import { mapActions } from 'vuex'
import LoginDialogCard from '@/components/layout/LoginDialogCard.vue'
import { EXPERT } from '@/store/const/userRoles'

export default {
  components: {
    LoginDialogCard,
  },
  data() {
    return {
      open: true,
      showPassword: false,
      email: '',
      password: '',
      showAlert: false,
      alertMessage: '',
    }
  },
  methods: {
    ...mapActions(['setLoggedUserId', 'setLoggedUserRole', 'setLoggedUserFio']),
    onInput() {
      this.showAlert = false
    },
    validateForm() {
      if (!this.email) {
        this.alertMessage = 'Введите email'
        this.showAlert = true
        return false
      }
      if (!this.password) {
        this.alertMessage = 'Введите пароль'
        this.showAlert = true
        return
      }
      return true
    },
    handleLoginError() {
      this.alertMessage = 'Неправильный логин или пароль'
      this.showAlert = true
    },
    handleLoginSuccess(user) {
      this.setLoggedUserId(user.id)
      this.setLoggedUserRole(user.role)
      this.setLoggedUserFio(user.fio)
      if (user.role === EXPERT) {
        this.$router.push(`/expert`)
      } else {
        this.$router.push(`/admin`)
      }
    },
    async onLogin() {
      if (!this.validateForm()) return
      try {
        const { email, password } = this
        const user = await axios.post('/api/auth/login', {
          email,
          password,
        })
        this.handleLoginSuccess(user.data)
      } catch (err) {
        this.handleLoginError()
      }
    },
  },
}
</script>
